<template>
  <tr>
    <td v-if="ckey === 0" :rowspan="alert.data.no_respect.length">
        {{ alert.dateToLocal() }}
    </td>
    <td v-if="ckey === 0" :rowspan="alert.data.no_respect.length">
      {{ alert.settings.hour_check }}
    </td>
    <td>
      {{item.lvl}}
    </td>
    <td>
      {{item.id}}
    </td>
    <td>
      {{item.percent_spent}}
    </td>
    <td>
      {{item.spend}}
    </td>
    <td>
      {{item.obj}}
    </td>
  </tr>
</template>

<script>
import UltraDetailedMixin from './mixin/UltraDetailedMixin'

export default {
  mixins: [UltraDetailedMixin],
  name: 'IntradayPacingOffTracksDetailed',
  props: [],
  components: {

  },
  data: function () {
    return {

    }
  },
  created: function () {

  },
  mounted: function () {

  },
  methods: {

  },
  computed: {

  },
  watch: {

  }
}
</script>

<style>
</style>
